.header-subscribe {
    display: inline-block;
    margin-left: 24px;
}

.header-subscribe-bar {
    background: #2B7ED7;
    text-align: center;
    min-height: 48px;
    line-height: 48px;
    color: white;
    a {
        color: white
    }
}

.header-subscribe-bar-white {
    background: white;
    text-align: center;
    min-height: 48px;
    line-height: 48px;
    padding: 0px 10px 2px 0px;
    color: #2B7ED7;
    a {
        color: #2B7ED7;
    }
}

.header-subscribe-bar-green {
    background: #59C279;
    text-align: center;
    min-height: 48px;
    line-height: 48px;
    padding: 8px 10px 0px 0px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;

    p {
        font-family: gotham, sans-serif;
        margin-top: 7px;
        font-size: 20px;
        line-height: 47px;
    }

    a {
        color: white;
    }

    a:hover .btn-success {
        border-color: green;
    }
    .btn-success {
        background: #385cf6;
        color: white;
        margin-top: -10px;
        text-transform: uppercase;
        padding: 2px 10px 2px 11px;
        border-radius: 30px;
        font-size: 16px;
        margin-right: 5px;
        margin-left: 10px;
        height: 29px;
        font-family: gotham, sans-serif;
    }
}


.baner {
    .wraper-header-common {
        width: 100%;

        p {
            width: 100%;
            font-family: gotham, sans-serif;
            font-size: 14px;
            //background: linear-gradient(to right, #385cf6, #218bf6);
            color: #fff;
            padding: 5px 10px 7px 10px;
            //background: #218bf6;
            display: inline-block;
            //margin-top: -42px;
            margin-top: 5px;

            a {
                //background: #00d392;
                text-transform: uppercase;
                padding: 2px 5px;
                border-radius: 30px;
                font-size: 12px;
                margin-right: 5px;
                margin-left: 10px;

            }
        }
    }

    &.fixed-top{
        margin-top: 60px;
    }


    &.home-baner {

        .wraper-header-common {
            background: linear-gradient(to right, #385cf6, #218bf6);
            p{
                background: linear-gradient(to right, #385cf6, #218bf6);
                a{

                }
            }
        }

        .btn-banner{
            background-color: #00c68c;;
            color: white;
        }

    }

    &.green-banner{
        .wraper-header-common {
            background: #48c07c;
        }


        .btn-banner{
            background-color: white;
            color: #00d392;
        }
    }


    &.mobile{
        position: fixed !important;
        bottom: 0px !important;
        width: 100% !important;
        z-index: 100 !important;
        height: auto;
        .mobile-fixed-bottom{
            height: 86px !important;

            a{
                margin-top: 4px;
            }

            p{
                margin-top: 2px !important;
            }
        }
    }

}


@media (max-width: 575.98px) { 
    

}
